<template>
  <div class="vm-modal">
    <div v-if="isServiceTypeEcommerce">
      <h2 class="normal" v-html="$t('configuration.paywayGroups.otp.paykaModalEcommerce.header2')"></h2>
      <p v-html="$t('configuration.paywayGroups.otp.paykaModalEcommerce.text2')"></p>
    </div>
    <div v-if="isServiceTypeMwf">
      <h2 class="normal" v-html="$t('configuration.paywayGroups.otp.paykaModalMwf.header1')"></h2>
      <p v-html="$t('configuration.paywayGroups.otp.paykaModalMwf.text1')"></p>
      <h2 class="normal" v-html="$t('configuration.paywayGroups.otp.paykaModalMwf.header2')"></h2>
      <p v-html="$t('configuration.paywayGroups.otp.paykaModalMwf.text2')"></p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { PAYMENT_SERVICE_TYPE } from '@/base/const/payment.const'

export default {
  name: 'PaykaModal',
  computed: {
    ...mapState({
      isServiceTypeEcommerce: state => state.payment.paymentData.serviceType === PAYMENT_SERVICE_TYPE.ecommerce,
      isServiceTypeMwf: state => state.payment.paymentData.serviceType === PAYMENT_SERVICE_TYPE.mwf
    })
  }
}
</script>
